const state = {
  globals: {
    user: {},
    usersListe: [],
    supportsListe: [],
    referentielMLsListe: [],
    referentielBoitesListe: [],
    referentielScoresListe: [],
    loading: false,
    showAppHeader: false,
    showAppFooter: false,
    isUserLogged: false,
    workflow: {},
  },
};

export default state;
