<template>
  <loading v-if="loading" />
  <div v-else>
    <div data-label="Nom">
      <strong>Nom : </strong>
      <span>{{ score.nom }}</span>
    </div>
    <div data-label="Description">
      <strong>Description : </strong>
      <span>{{ score.description }}</span>
    </div>
    <div data-label="Actif">
      <strong>Actif : </strong>
      <span>{{ score.is_actif ? "Oui" : "Non" }}</span>
    </div>
    <div data-label="Date de création">
      <strong>Date de création : </strong>
      <span>{{ getDateFormat(score.created_at, "DD/MM/YYYY") }}</span>
    </div>
    <div data-label="Date de MAj">
      <strong>Date de MAj : </strong>
      <span>{{ getDateFormat(score.updated_at, "DD/MM/YYYY") }}</span>
    </div>
    <div class="form-footer flex-btn-group center">
      <router-link class="btn btn-secondary" :to="{ name: 'Admin.Referentiel.Score' }"
        >Retour à la liste</router-link
      >
      <router-link
        class="btn btn-secondary"
        :to="{ name: 'Admin.Referentiel.Score.Edit', params: { id: score.referentiel_id } }"
      >
        Modifier</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "../../../../components/layouts/Loading.vue";
export default {
  components: { Loading },
  name: "DetailsScore",
  data() {
    return {
      score: {},
    };
  },
  computed: {
    ...mapGetters(["loading"]),
  },
  methods: {
    ...mapActions(["getReferentielScore", "setLoading"]),
    getDateFormat(d, format) {
      return this.$moment(d).format(format);
    },
  },
  created() {
    return this.setLoading(true)
      .then(() => {
        let idReferentielScore = null;
        if (this.$route && this.$route.params && this.$route.params.id) {
          idReferentielScore = this.$route.params.id;
        }
        if (idReferentielScore) {
          return this.getReferentielScore({ id: idReferentielScore });
        }
      })
      .then(score => {
        if (score) this.score = { ...score };
      })
      .then(() => this.setLoading(false));
  },
};
</script>
