<template>
  <h2>Référentiel</h2>
  <nav class="tabs">
    <router-link class="tab" :to="{ name: 'Admin.Referentiel.Images' }">Images</router-link>
    <router-link class="tab" :to="{ name: 'Admin.Referentiel.Signatures' }">Signatures</router-link>
    <router-link class="tab" :to="{ name: 'Admin.Referentiel.ML' }">ML</router-link>
    <router-link class="tab" :to="{ name: 'Admin.Referentiel.Boite' }">Boites d'exclusion</router-link>
    <router-link class="tab" :to="{ name: 'Admin.Referentiel.Score' }">Scores clients</router-link>
  </nav>
  <div class="content tab-content">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "AdminReferentiel",
};
</script>

<style></style>
