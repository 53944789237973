<template>
  <form-score />
</template>

<script>
import FormScore from "./FormScore.vue";
export default {
  components: { FormScore },
  name: "CreateScore",
};
</script>
