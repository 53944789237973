<template>
  <tr>
    <td data-label="Nom">
      <span>{{ score.nom }}</span>
    </td>
    <td data-label="Date de création">
      <span>{{ getDateFormat(score.created_at, "DD/MM/YYYY") }}</span>
    </td>
    <td data-label="Date de MAj">
      <span>{{ getDateFormat(score.updated_at, "DD/MM/YYYY") }}</span>
    </td>
    <td data-label="Description">
      <span>{{ score.description }}</span>
    </td>
    <td data-label="Actif">
      <span>{{ score.is_actif ? "Oui" : "Non" }}</span>
    </td>
    <td class="buttons">
      <router-link
        class="btn tooltip-btn"
        :to="{ name: 'Admin.Referentiel.Score.Details', params: { id: score.referentiel_id } }"
      >
        <i class="fal fa-search"></i>
        <span class="tooltip-text">Visualiser</span>
      </router-link>
      <router-link
        class="btn tooltip-btn"
        :to="{ name: 'Admin.Referentiel.Score.Edit', params: { id: score.referentiel_id } }"
      >
        <i class="fal fa-pencil"></i>
        <span class="tooltip-text">Modifier</span>
      </router-link>
      <button class="btn tooltip-btn" @click="$emit('deleteScore', score.referentiel_id)">
        <i class="fal fa-trash-alt"></i>
        <span class="tooltip-text">Supprimer</span>
      </button>
      <button
        class="btn tooltip-btn"
        @click="$emit('desactiverScore', score.referentiel_id, !score.is_actif)"
      >
        <i class="fal fa-power-off"></i>
        <span class="tooltip-text">{{ score.is_actif ? "Désactiver" : "Activer" }} </span>
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  name: "ItemScore",
  emits: ["deleteScore", "desactiverScore"],
  props: {
    score: {
      type: Object,
      default: () => {},
    },
  },
  computed: {},
  methods: {
    getDateFormat(d, format) {
      return d ? this.$moment(d).format(format) : "-";
    },
  },
};
</script>
