import {
  actions as actionsUsers,
  mutations as mutationsUsers,
  getters as gettersUsers,
} from "./users";

import {
  actions as actionsSupports,
  mutations as mutationsSupports,
  getters as gettersSupports,
} from "./supports";

import {
  actions as actionsGlobal,
  mutations as mutationsGlobal,
  getters as gettersGlobal,
} from "./globals";

import {
  actions as actionsBriefs,
  mutations as mutationsBriefs,
  getters as gettersBriefs,
} from "./briefs";

import {
  actions as actionsReferentielImages,
  mutations as mutationsReferentielImages,
  getters as gettersReferentielImages,
} from "./referentielImages";

import {
  actions as actionsCiblage,
  mutations as mutationsCiblage,
  getters as gettersCiblage,
} from "./ciblage";
import {
  actions as actionsSupportEtapes,
  mutations as mutationsSupportEtapes,
  getters as gettersSupportEtapes,
} from "./support_etapes";
import {
  actions as actionsRefML,
  mutations as mutationsRefML,
  getters as gettersRefML,
} from "./referentielML";
import {
  actions as actionsRefBoites,
  mutations as mutationsRefBoites,
  getters as gettersRefBoites,
} from "./referentielBoites";
import {
  actions as actionsRefScores,
  mutations as mutationsRefScores,
  getters as gettersRefScores,
} from "./referentielScores";

export const actions = {
  ...actionsGlobal,
  ...actionsUsers,
  ...actionsSupports,
  ...actionsReferentielImages,
  ...actionsBriefs,
  ...actionsCiblage,
  ...actionsSupportEtapes,
  ...actionsRefML,
  ...actionsRefBoites,
  ...actionsRefScores,
};
export const mutations = {
  ...mutationsGlobal,
  ...mutationsUsers,
  ...mutationsSupports,
  ...mutationsBriefs,
  ...mutationsReferentielImages,
  ...mutationsCiblage,
  ...mutationsSupportEtapes,
  ...mutationsRefML,
  ...mutationsRefBoites,
  ...mutationsRefScores,
};
export const getters = {
  ...gettersUsers,
  ...gettersSupports,
  ...gettersGlobal,
  ...gettersBriefs,
  ...gettersReferentielImages,
  ...gettersCiblage,
  ...gettersSupportEtapes,
  ...gettersRefML,
  ...gettersRefBoites,
  ...gettersRefScores,
};
